import "./Navbar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import cartIcon from "../../assets/cart.png";
import siniLogo from "../../assets/logoThree.png";
import { BiMenuAltLeft } from "react-icons/bi";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/login";
import { motion } from "framer-motion";

const Navbar = ({ setSidebar, setOpenCart }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(null);
  const user = localStorage.getItem("user");
  const { cart } = useSelector((state) => state.cart);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
    localStorage.removeItem("user");
    navigate(0);
  };

  return (
    <div className="navbar">
      <motion.div
        initial={{ opacity: 0.3 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="wrapper"
      >
        <div className="menuContainer">
          {width <= 1000 && (
            <div className="menu" onClick={() => setSidebar(true)}>
              <BiMenuAltLeft />
            </div>
          )}
        </div>
        <div className="left">
          <NavLink className="link" to="/">
            <img className="logo" src={siniLogo} alt="sini coffee Logo" />
          </NavLink>
        </div>
        <div className="center">
          <ul className="list">
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/shop"
              >
                Shop
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/blog"
              >
                Blog
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/about-us"
              >
                About
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/contact-us"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="right">
          {!user ? (
            <div className="linkContainer">
              <NavLink className="link" to="login">
                Login
              </NavLink>
              <span className="divider">|</span>
              <NavLink className="link" to="/register">
                Register
              </NavLink>
            </div>
          ) : (
            <div className="linkContainer">
              <NavLink className="link" to="profile">
                Profile
              </NavLink>
              <span className="divider">|</span>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
          <Link to="/cart">
            <div className="cartIcon">
              <img src={cartIcon} alt="cart icon" />
              <span className="items">{cart && cart.length}</span>
            </div>
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Navbar;
