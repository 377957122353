import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  blogCategories: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchBlogCategory = () => async (dispatch) => {
  dispatch({ type: "blogCategory/isFetching" });

  try {
    const response = await normalRequest.get(
      `/blog-categories/get-blog-categories`
    );

    dispatch({
      type: "blogCategory/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blogCategory/fetchError",
      payload: error.response.data.message,
    });
  }
};

const blogCategoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("blogCategory/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogCategories = null;
    })
    .addCase("blogCategory/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.blogCategories = action.payload;
    })
    .addCase("blogCategory/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.blogCategories = null;
    });
});

export default blogCategoryReducer;
