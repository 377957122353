import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  isRegistering: false,
  registerSuccess: false,
  registerError: null,
  serverResponse: null,
};

export const registerUser =
  (name, email, password, confirmPassword) => async (dispatch) => {
    dispatch({ type: "register/registering" });

    try {
      const response = await normalRequest.post("/users/create-user", {
        name,
        email,
        password,
        confirmPassword,
      });
      dispatch({
        type: "register/registerSuccess",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "register/registerError",
        payload: error.response.data.message,
      });
    }
  };

const registerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("register/registering", (state) => {
      state.isRegistering = true;
      state.registerSuccess = false;
      state.registerError = null;
      state.serverResponse = null;
    })
    .addCase("register/registerSuccess", (state, action) => {
      state.isRegistering = false;
      state.registerSuccess = true;
      state.serverResponse = action.payload;
    })
    .addCase("register/registerError", (state, action) => {
      state.isRegistering = false;
      state.registerError = action.payload;
      state.serverResponse = action.payload;
    });
});

export default registerReducer;
