import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  productCategories: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchProductCategory = () => async (dispatch) => {
  dispatch({ type: "productCategory/isFetching" });

  try {
    const response = await normalRequest.get(
      `/product-categories/get-product-categories`
    );

    dispatch({
      type: "productCategory/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "productCategory/fetchError",
      payload: error.response.data.message,
    });
  }
};

const productCategoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("productCategory/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.productCategories = null;
    })
    .addCase("productCategory/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.productCategories = action.payload;
    })
    .addCase("productCategory/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.productCategories = null;
    });
});

export default productCategoryReducer;
