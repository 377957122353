import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  blogs: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchBlog = () => async (dispatch) => {
  dispatch({ type: "blog/isFetching" });

  try {
    const response = await normalRequest.get(`/blogs/get-blogs`);

    dispatch({
      type: "blog/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blog/fetchError",
      payload: error.response.data.message,
    });
  }
};

const blogReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("blog/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogs = null;
    })
    .addCase("blog/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.blogs = action.payload;
    })
    .addCase("blog/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.blogs = null;
    });
});

export default blogReducer;
