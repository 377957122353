import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./blog";
import blogCategoryReducer from "./blogCategory";
import cartReducer from "./cart";
import GalleryReducer from "./gallery";
import loginReducer from "./login";
import orderReducer from "./order";
import placeReducer from "./pickupPlace";
import productReducer from "./product";
import productCategoryReducer from "./productCategory";
import registerReducer from "./register";

export const store = configureStore({
  reducer: {
    register: registerReducer,
    login: loginReducer,
    product: productReducer,
    productCategory: productCategoryReducer,
    blog: blogReducer,
    blogCategory: blogCategoryReducer,
    cart: cartReducer,
    place: placeReducer,
    order: orderReducer,
    gallery: GalleryReducer,
  },
});
