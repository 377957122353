import { useState, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";

const Home = lazy(() => import("./pages/home/Home"));
const CheckoutPage = lazy(() => import("./pages/checkout/Checkout.jsx"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Shop = lazy(() => import("./pages/shop/Shop"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const SingleProduct = lazy(() => import("./pages/product/SingleProduct"));
const SingleBlog = lazy(() => import("./pages/singleBlog/SingleBlog"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const LoginPage = lazy(() => import("./pages/login/Login"));
const RegisterPage = lazy(() => import("./pages/register/Regsiter"));
const ActivationPage = lazy(() => import("./pages/activation/ActivationPage"));
const ForgotPasswordPage = lazy(() =>
  import("./pages/forgotPassword/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("./pages/resetPassword/ResetPasswordPage")
);
const Profile = lazy(() => import("./pages/profile/Profile"));
const CheckOrder = lazy(() => import("./pages/checkOrder/CheckOrder"));
const OrderStatus = lazy(() => import("./pages/orderStatus/OrderStatus"));
const OrderSuccessPage = lazy(() =>
  import("./pages/orderSuccess/OrderSuccess")
);
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/refundPolicy/RefundPolicy"));
const NotFound = lazy(() => import("./pages/404/NotFound"));

const App = () => {
  const [sidebar, setSidebar] = useState(false);

  const user = localStorage.getItem("user");

  return (
    <div>
      <BrowserRouter>
        <Navbar setSidebar={setSidebar} />
        {sidebar && window.innerWidth <= 900 && (
          <Sidebar setSidebar={setSidebar} />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/" replace /> : <LoginPage />}
          />
          <Route
            path="/register"
            element={user ? <Navigate to="/" replace /> : <RegisterPage />}
          />

          <Route
            path="/activation/:activation_token"
            element={user ? <Navigate to="/" replace /> : <ActivationPage />}
          />
          <Route
            path="/forgot-password"
            element={
              user ? <Navigate to="/" replace /> : <ForgotPasswordPage />
            }
          />
          <Route
            path="/reset-password/:reset_token"
            element={user ? <Navigate to="/" replace /> : <ResetPasswordPage />}
          />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/product/:slug" element={<SingleProduct />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<SingleBlog />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="/profile"
            element={user ? <Profile /> : <Navigate to="/login" />}
          />
          <Route path="/check-order" element={<CheckOrder />} />
          <Route path="/order-status/:orderNo" element={<OrderStatus />} />
          <Route
            path="/order-success/:orderNo"
            element={<OrderSuccessPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
