import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../utils/utils";

const initialState = {
  isLoggingIn: false,
  loginSuccess: false,
  loginError: null,
  serverResponse: null,
};

export const loginUser = (email, password) => async (dispatch) => {
  dispatch({ type: "login/loggingIn" });

  try {
    const response = await normalRequest.post("/users/login-user", {
      email,
      password,
    });
    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    dispatch({
      type: "login/loginSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "login/loginError",
      payload: error.response.data.message,
    });
  }
};

export const logoutUser = (email, password) => async (dispatch) => {
  try {
    await authenticatedRequest.get(`/users/logout`, {
      withCredentials: true,
    });
  } catch (error) {
    console.log(error);
  }
};

const loginReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("login/loggingIn", (state) => {
      state.isLoggingIn = true;
      state.loginSuccess = false;
      state.loginError = null;
      state.serverResponse = null;
    })
    .addCase("login/loginSuccess", (state, action) => {
      state.isLoggingIn = false;
      state.loginSuccess = true;
      state.loginError = null;
      state.serverResponse = action.payload;
    })
    .addCase("login/loginError", (state, action) => {
      state.isLoggingIn = false;
      state.loginError = action.payload;
      state.serverResponse = action.payload;
    });
});

export default loginReducer;
