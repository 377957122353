import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  isApplyingCoupon: false,
  couponSuccess: null,
  couponError: null,
};

export const applyCoupon = (data) => async (dispatch) => {
  dispatch({ type: "order/applyingCoupon" });

  try {
    const response = await normalRequest.put(`/orders/apply-coupon`, {
      orderId: data.orderId,
      couponCode: data.couponCode,
    });
    dispatch({
      type: "order/couponSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "order/couponError",
      payload: error.response.data.message,
    });
  }
};

const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("order/applyingCoupon", (state) => {
      state.isApplyingCoupon = true;
      state.couponSuccess = null;
      state.couponError = null;
    })
    .addCase("order/couponSuccess", (state, action) => {
      state.isApplyingCoupon = false;
      state.couponSuccess = action.payload;
      state.couponError = null;
    })
    .addCase("order/couponError", (state, action) => {
      state.isApplyingCoupon = false;
      state.couponSuccess = null;
      state.couponError = action.payload;
    });
});

export default orderReducer;
