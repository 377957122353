import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchGalleries = () => async (dispatch) => {
  dispatch({ type: "gallery/isFetching" });

  try {
    const response = await normalRequest.get(`/galleries/fetch-gallery`);

    dispatch({
      type: "gallery/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "gallery/fetchError",
      payload: error.response.data.message,
    });
  }
};

const GalleryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("gallery/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
    })
    .addCase("gallery/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = action.payload;
      state.fetchError = null;
    })
    .addCase("gallery/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = null;
      state.fetchError = action.payload;
    });
});

export default GalleryReducer;
