import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchPlaces = () => async (dispatch) => {
  dispatch({ type: "pickupPlace/isFetching" });

  try {
    const response = await normalRequest.get(`/pickup-places/places`);

    dispatch({
      type: "pickupPlace/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "pickupPlace/fetchError",
      payload: error.response.data.message,
    });
  }
};

const placeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("pickupPlace/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
    })
    .addCase("pickupPlace/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = action.payload;
      state.fetchError = null;
    })
    .addCase("pickupPlace/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = null;
      state.fetchError = action.payload;
    });
});

export default placeReducer;
