import "./Sidebar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  AiFillHome,
  AiOutlineShop,
  AiOutlineCoffee,
  AiOutlineMessage,
  AiFillCloseCircle,
} from "react-icons/ai";
import { ImNewspaper } from "react-icons/im";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/login";

const Sidebar = ({ setSidebar }) => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
    localStorage.removeItem("user");
    navigate(0);
  };

  return (
    <div className="sidebar">
      <div className="wrapper">
        <div className="top">
          <button
            onClick={() => setSidebar(false)}
            className="hover:drop-shadow-lg justify-end hover:bg-light-gray"
            type="button"
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <div className="middle">
          <ul onClick={() => setSidebar(false)} className="list">
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/"
              >
                <AiFillHome />
                Home
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/shop"
              >
                <AiOutlineShop />
                Shop
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/blog"
              >
                <ImNewspaper />
                Blog
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/about-us"
              >
                <AiOutlineCoffee />
                About
              </NavLink>
            </li>
            <li className="listItem">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "link")}
                to="/contact-us"
              >
                <AiOutlineMessage />
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="bottom">
          {!user ? (
            <div onClick={() => setSidebar(false)} className="linkContainer">
              <NavLink className="link" to="login">
                Login
              </NavLink>
              <span className="divider">|</span>
              <NavLink className="link" to="/register">
                Register
              </NavLink>
            </div>
          ) : (
            <div onClick={() => setSidebar(false)} className="bottomItems">
              <Link className="profile" to="/profile">
                Profile
              </Link>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
        <div className="socials">
          <ul className="list">
            <a
              target="_blank"
              href="https://www.facebook.com/sinicoffeeShop"
              rel="noreferrer"
            >
              <li className="listItem">
                <FaFacebookF />
              </li>
            </a>
            <a
              target="_blank"
              href="https://www.tiktok.com/@temusini1"
              rel="noreferrer"
            >
              <li className="listItem">
                <FaTiktok />
              </li>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@sinicoffeeShop"
              rel="noreferrer"
            >
              <li className="listItem">
                <FaYoutube />
              </li>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/sinicoffeeShop"
              rel="noreferrer"
            >
              <li className="listItem">
                <FaInstagram />
              </li>
            </a>
            <a target="_blank" href="https://t.me/hcfej" rel="noreferrer">
              <li className="listItem">
                <FaTelegram />
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
