import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  products: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
};

export const fetchProduct = () => async (dispatch) => {
  dispatch({ type: "product/isFetching" });

  try {
    const response = await normalRequest.get(`/products/get-products`);

    dispatch({
      type: "product/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "product/fetchError",
      payload: error.response.data.message,
    });
  }
};

const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("product/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.products = null;
    })
    .addCase("product/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.products = action.payload;
    })
    .addCase("product/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.products = null;
    });
});

export default productReducer;
