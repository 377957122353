import { createReducer } from "@reduxjs/toolkit";
import { normalRequest } from "../utils/utils";

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  isCheckingout: false,
  checkoutSuccess: null,
  checkoutError: null,
};

export const addToCart = (data) => async (dispatch, getState) => {
  dispatch({ type: "cart/addToCart", payload: data });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

export const removeFromCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "cart/removeFromCart",
    payload: data._id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

export const checkout = (data) => async (dispatch) => {
  dispatch({ type: "checkout/isCheckingout" });
  try {
    const response = await normalRequest.post(`/orders/checkout`, {
      cart: data.cart,
      totalPrice: data.totalPrice,
      instruction: data.instruction,
    });
    dispatch({
      type: "checkout/checkoutSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "checkout/checkoutError",
      payload: error.resonse.data.message,
    });
  }
};

const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("cart/addToCart", (state, action) => {
      const item = action.payload;
      const isItemExist = state.cart.find((i) => i._id === item._id);
      if (isItemExist) {
        return {
          ...state,
          cart: state.cart.map((i) => (i._id === isItemExist._id ? item : i)),
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, item],
        };
      }
    })

    .addCase("cart/removeFromCart", (state, action) => {
      return {
        ...state,
        cart: state.cart.filter((i) => i._id !== action.payload),
      };
    })
    .addCase("checkout/isCheckingout", (state) => {
      state.isCheckingout = true;
      state.checkoutSuccess = null;
      state.checkoutError = null;
    })
    .addCase("checkout/checkoutSuccess", (state, action) => {
      state.isCheckingout = false;
      state.checkoutSuccess = action.payload;
      state.checkoutError = null;
    })
    .addCase("checkout/checkoutError", (state, action) => {
      state.isCheckingout = false;
      state.checkoutSuccess = null;
      state.checkoutError = action.payload;
    });
});

export default cartReducer;
