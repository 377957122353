import "./Footer.scss";
import logo from "../../assets/logoNew.png";
import {
  FaFacebookF,
  FaTiktok,
  FaYoutube,
  FaInstagram,
  FaTelegram,
} from "react-icons/fa";
import { MdLocationOn, MdPhoneIphone, MdAlternateEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(`/${path}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <div className="wrapper">
        <div className="top">
          <div className="left">
            <img src={logo} alt="sini coffee logo" />
            <p className="desc">
              We offer traditional Ethiopian coffee made from freshly roasted
              beans.
            </p>
            <ul className="list">
              <a
                target="_blank"
                href="https://www.facebook.com/sinicoffeeShop"
                rel="noreferrer"
              >
                <li className="listItem">
                  <FaFacebookF />
                </li>
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@temusini1"
                rel="noreferrer"
              >
                <li className="listItem">
                  <FaTiktok />
                </li>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@sinicoffeeShop"
                rel="noreferrer"
              >
                <li className="listItem">
                  <FaYoutube />
                </li>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/sinicoffeeShop"
                rel="noreferrer"
              >
                <li className="listItem">
                  <FaInstagram />
                </li>
              </a>
              <a target="_blank" href="https://t.me/hcfej" rel="noreferrer">
                <li className="listItem">
                  <FaTelegram />
                </li>
              </a>
            </ul>
          </div>
          <div className="center">
            <span className="title">Contact Us</span>
            <div className="info">
              <div className="iconContainer">
                <MdLocationOn className="icon" />
              </div>
              <div className="detail">
                <span>Location:</span>
                <p>
                  Jemo 1 Condominium Behind Kelela Building, Addis Ababa,
                  Ethiopia.
                </p>
              </div>
            </div>
            <div className="info">
              <div className="iconContainer">
                <MdPhoneIphone className="icon" />
              </div>
              <div className="detail">
                <span>Phone Number:</span>
                <p>09 13 02 77 33</p>
              </div>
            </div>
            <div className="info">
              <div className="iconContainer">
                <MdAlternateEmail className="icon" />
              </div>
              <div className="detail">
                <span>Email Address:</span>
                <p>info@sinicoffeeshop.com</p>
              </div>
            </div>
          </div>
          <div className="right">
            <span className="title">Links</span>
            <div className="linkList">
              <span onClick={() => handleClick("")}>Home</span>
              <span onClick={() => handleClick("shop")}>Shop</span>
              <span onClick={() => handleClick("blog")}>Blog</span>
              <span onClick={() => handleClick("about-us")}>About</span>
              <span onClick={() => handleClick("contact-us")}>Contact</span>
              <span onClick={() => handleClick("privacy-policy")}>
                Privacy Policy
              </span>
              <span onClick={() => handleClick("refund-policy")}>
                Refund Policy
              </span>
            </div>
          </div>
        </div>
        <div className="bottom">
          <span className="copyright">
            <b style={{ fontSize: "16px", marginRight: "5px" }}>&copy;</b>{" "}
            copyright {new Date().getFullYear()} Sini Coffee Shop. all rights
            reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
